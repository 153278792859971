import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { ToastrService } from 'ngx-toastr';
//import { DefaultService } from 'src/app/containers/default-layout/default.service';
//import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
//import { MenuItems } from 'src/app/shared/menu-items/menu-items';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  public form: FormGroup = Object.create(null);
  loader: Boolean = false;

  constructor(private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private toastr: ToastrService,
    private cookieService: CookieService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])]
    });
  }

  onSubmit() {
    this.loader = true;
    localStorage.removeItem('jwtToken');
    const data = (this.form || {}).value || {};
    if (this.form.valid) {
      this.loginService.login(data).subscribe((res) => {    
        const token = (res || {}).jwt || '';
        localStorage.setItem('jwtToken', token);
        this.loginService.loginUserData().subscribe(resp => {
          localStorage.setItem('customerId', resp.customerId);
          localStorage.setItem('role', resp.role);
          localStorage.setItem('loginUserId', resp.id);
          localStorage.setItem('user_name', resp.login);
          if (resp.role == "Super Admin" ) {
            localStorage.setItem('superAdmin', "true");
          } else {
            localStorage.setItem('superAdmin', "false");
          }
          localStorage.setItem('branchId', resp.branchInfoId);
          localStorage.setItem('maintainStock', resp.maintainStock);
          
          let uData = {
            'consultingFirmId': resp.consulting_firm_id,
            'customerId': resp.customer_id,
            'roles': resp.roles
          }
          //this.defaultService.storageDataSource.next(uData);
          //this.toastr.success("Logged In Successfuly");
          this.router.navigateByUrl('/dashboard');
          
          if (sessionStorage.getItem('rememberMe') == "true") {
            this.cookieService.set("username", resp.userName);
            this.cookieService.set("password", resp.password);
          } else {
            this.cookieService.set("username", "");
            this.cookieService.set("password", "");
          }
          sessionStorage.setItem('minisidebar', JSON.stringify(false));
          this.loader = false;
        });
      },(err) => {
          this.toastr.error('Invalid Username or Password');
          this.loader = false;
      });
    }else{
      this.toastr.error('Invalid Username or Password');
      this.loader = false; 
    }
  }
}
