<!--sidebar-->
<c-sidebar
  #sidebar="cSidebar"
  class="d-print-none sidebar sidebar-fixed"
  id="sidebar"
  visible
>
  <c-sidebar-brand
    [brandFull]="{
      src: 'assets/img/brand/bb_final_icon.png',
      width: 200,
      height: 55,
      alt: 'CoreUI Logo'
    }"
    [brandNarrow]="{
      src: 'assets/img/brand/coreui-signet-white.svg',
      width: 46,
      height: 46,
      alt: 'CoreUI Logo'
    }"
    routerLink="./"
  >
  </c-sidebar-brand>
    <perfect-scrollbar [config]="perfectScrollbarConfig">
  <!--  [perfectScrollbar]="perfectScrollbarConfig"-->
  <c-sidebar-nav
    [navItems]="navItems"
    dropdownMode="close"
  >
  </c-sidebar-nav>
    </perfect-scrollbar>
  <!-- <c-sidebar-toggler
    *ngIf="!sidebar.narrow"
    toggle="unfoldable"
    cSidebarToggle="sidebar"
  ></c-sidebar-toggler> -->
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-header-->
  <app-default-header class="d-print-none header header-sticky" style="margin-bottom: 0.5rem !important;" position="sticky" sidebarId="sidebar"></app-default-header>
  <!--app-body-->
  <div class="body flex-grow-1">
    <c-container breakpoint="lg" class="h-auto">
      <router-outlet>
        <app-spinner></app-spinner>
        <ng-http-loader *ngIf="globalServiceInstance.loaderValue" class="overlay-loader" [backgroundColor]="'#5184BE'" [spinner]="spinkit.skThreeBounce"></ng-http-loader>

        <!-- <ng-http-loader></ng-http-loader> -->
        <!-- <ng-http-loader [backdrop]="true" [debounceDelay]="100" [extraDuration]="300" [minDuration]="300" [backgroundColor]="'#767676'" [spinner]="spinkit.skThreeBounce"></ng-http-loader> -->

      </router-outlet>
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer></app-default-footer>
</div>
