<div class="bg-light min-vh-100 d-flex align-items-center justify-content-center">
  <div class="background-image"></div>
  <c-container>
    <c-row class="justify-content-left">
      <c-col xs="11" sm="9" md="7" lg="5">
        <c-card-group>
          <c-card class="p-4 login-card">
            <c-card-body class="text-center">
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <!-- <h1 style="color: white; font-family: inherit; text-align: left;">Sign In</h1>
                <br/> -->
                <!-- <p class="text-medium-emphasis">Sign In to your account</p> -->
                <c-input-group class="mb-3">
                  <span cInputGroupText class="icon-f">
                    <svg cIcon name="cilUser"></svg>
                  </span>
                  <input autoComplete="username" formControlName="username" placeholder="Username" class="form-control input-f" />
                </c-input-group>
                <c-input-group class="mb-4">
                  <span cInputGroupText class="icon-f">
                    <svg cIcon name="cilLockLocked"></svg>
                  </span>
                  <input autoComplete="current-password" formControlName="password" placeholder="Password" type="password" class="form-control input-f" />
                </c-input-group>
                <c-row>
                  <c-col xs="12">
                    <button cButton class="px-4 login-btn" color="primary" type="button" (click)="onSubmit()">
                      Sign In
                    </button>
                  </c-col>
                </c-row>
                <c-row style="margin-top: 10px;">
                  <c-col *ngIf="loader" xs="12">
                    <c-spinner color="info"></c-spinner>
                  </c-col>
                </c-row>
              </form>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div>
