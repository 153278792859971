import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }

  public loader: boolean = false;

  get loaderValue(): boolean {
    return this.loader;
  }

  set loaderValue(value: boolean) {
    this.loader = value;
  }
}
