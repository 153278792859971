import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { FullService } from '../../full.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent {

  @Input() sidebarId: string = "sidebar";

  public newMessages = new Array(4)
  public newTasks = new Array(5)
  public newNotifications = new Array(5)

  customerList: any[] = [];
  branchList: any[] = [];
  userRole : any;
  isSuperAdmin: boolean = false;
  cid = new FormControl('');
  branchId = new FormControl('');

  constructor(private classToggler: ClassToggleService,
    private fullService: FullService,
    private toastrService : ToastrService,
    private router : Router) {
    super();
  }

  ngOnInit() {
    this.userRole = localStorage.getItem('role');
    if(this.userRole == 'Super Admin'){
      this.isSuperAdmin = true;
    }else {
      this.isSuperAdmin = false;
    }
    this.getCustomerList(); 
    this.getBranchListByCustomer(false);
  }

  getCustomerList() {
    this.fullService.getCustomerList().subscribe(resp => {
      var custId = localStorage.getItem('customerId');
      for(var i = 0; i < resp.length; i++){
        if(this.isSuperAdmin){
          this.customerList.push(resp[i]);  
        }else {
          if(custId == resp[i].id){
            this.customerList.push(resp[i]);  
          }  
        }
        if(custId == resp[i].id){
          this.cid.setValue(resp[i].id);      
        }
      }  
    });
  }

  getBranchListByCustomer(isCustomerChange : boolean) {
     this.fullService.getBranchListByCustomer(localStorage.getItem('customerId')).subscribe(resp => {
      if(resp && resp.length > 0) {
        if(this.userRole == 'Super Admin' || this.userRole == 'Admin' ){
          this.branchList = resp;
          var branchId = localStorage.getItem('branchId');
          if(branchId != null && branchId != "null") {
            this.branchId.setValue(branchId);
            for(var i = 0; i < this.branchList.length; i++){
              if(branchId == this.branchList[i].id){
                localStorage.setItem('maintainStock', this.branchList[i].maintainStock);
                localStorage.setItem('bloodStorage', this.branchList[i].bloodStorage);
              } 
            }
          }else {
            this.branchId.setValue(this.branchList[0].id);
            localStorage.setItem('branchId',this.branchList[0].id);
            localStorage.setItem('maintainStock', this.branchList[0].maintainStock);
            localStorage.setItem('bloodStorage', this.branchList[0].bloodStorage);
          } 
        }else {
          var branchId = localStorage.getItem('branchId');
          for(var i = 0; i < resp.length; i++){
             if(branchId == resp[i].id){
               this.branchList.push(resp[i]);
               this.branchId.setValue(resp[i].id);  
               localStorage.setItem('maintainStock', resp[i].maintainStock); 
               localStorage.setItem('bloodStorage', resp[i].bloodStorage);   
             }
          }
        }
        if(isCustomerChange){
          this.reloadCurrentRoute();
        }
      }    
     });
  }

  onCustomer(event: any) {		
		var custId = this.cid.value;
    if (custId !== null) {
      localStorage.setItem('customerId', custId);
      localStorage.removeItem('branchId');
      this.getBranchListByCustomer(true);
    } else {
      this.toastrService.error('Failed to change customer');
    }
	}

  onBranchChange(event: any) {		
		var branchId = this.branchId.value;
    if (branchId !== null) {
      localStorage.setItem('branchId', branchId);
      for(var i = 0; i < this.branchList.length; i++){
        if(branchId == this.branchList[i].id){
          localStorage.setItem('maintainStock', this.branchList[i].maintainStock);
          localStorage.setItem('bloodStorage', this.branchList[i].bloodStorage);
        } 
      }
      this.reloadCurrentRoute();
    } else {
      this.toastrService.error('Failed to change branch');
    }
	}

  reloadCurrentRoute() {
    this.router.navigate(['/camp']).then(() => {
      // Navigate back to the original route after a short delay (e.g., 10 milliseconds)
      setTimeout(() => {
        this.router.navigate(['/dashboard']);
      }, 10);
    });
  }

  logout() {
    this.fullService.logOut().subscribe(resp => {
      if(resp.status == "success"){
        localStorage.removeItem('jwtToken');
        this.router.navigate(['/login']); 
      }else {
        this.toastrService.error("Failed to logout!! Try Again!!!");
      }
    }, err => {
      this.toastrService.error("Failed to logout!! Try Again!!!");
    });
  }
}

function Integer(custId: string | null) {
  throw new Error('Function not implemented.');
}

