import { Component, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GlobalService } from '../global.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  
  constructor(private globalService: GlobalService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('jwtToken'); // Retrieve the JWT token from local storage
    this.globalService.loaderValue = true;
    if (token) {
      // Clone the request and add the token to the headers
      const modifiedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });

      return next.handle(modifiedRequest).pipe(
        finalize(() => {
          this.globalService.loaderValue = false;
        }));
    }

    return next.handle(request).pipe(
      finalize(() => {
        this.globalService.loaderValue = false;
      }));
  }
}
