<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-1"
    >
      <svg cIcon
        name="cilMenu"
        size="lg"
      ></svg>
    </button>
    <c-header-nav class="d-none d-lg-flex me-auto">
      <c-nav-item>
        <a cNavLink routerLink="/dashboard" routerLinkActive="active">
          Dashboard
        </a>
      </c-nav-item>
      <!-- <c-nav-item>
        <a cNavLink routerLink="/users" routerLinkActive="active">Users</a>
      </c-nav-item>
      <c-nav-item>
        <a cNavLink routerLink="/settings" routerLinkActive="active">
          Settings
        </a>
      </c-nav-item> -->
    </c-header-nav>

    <!-- <c-header-nav class="d-none d-lg-flex">
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilBell" size="lg"></svg>
        </a>
      </c-nav-item>
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilList" size="lg"></svg>
        </a>
      </c-nav-item>
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilEnvelopeOpen" size="lg"></svg>
        </a>
      </c-nav-item>
    </c-header-nav> -->
    <c-header-nav class="ms-3">
      <select class="form-select form-control form-control-sm font-size13 arrowArr"
          aria-label="Default select example" [formControl]="branchId" name="branchId"
          [disabled]="isSuperAdmin" (change)="onBranchChange($event)">
          <option *ngFor="let item of branchList" [value]="item.id">
            {{item.branchName}}
          </option>
      </select>
    </c-header-nav>

    <c-header-nav class="ms-3">
      <select class="form-select form-control form-control-sm font-size13 arrowArr"
          aria-label="Default select example" [formControl]="cid" name="custId"
          [disabled]="isSuperAdmin" (change)="onCustomer($event)">
          <option *ngFor="let item of customerList" [value]="item.id">
            {{item.customerName}}
          </option>
      </select>
    </c-header-nav>

    <c-header-nav class="ms-3">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>

  </c-container>
  <!-- <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
  </c-container> -->
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button
      cButton
      color=""
      [caret]="false"
      cDropdownToggle
      class="py-0"
    >
      <c-avatar
        cTextColor="primary"
        shape="rounded-1"
        size="md"
        src="assets/img/brand/user-icon.png"
        status="success"
      >
      </c-avatar>
    </button>
    <ul cDropdownMenu class="drop-down-btn">
      <li>
        <!-- <a routerLink="./" cDropdownItem (click)="logout()"> -->
        <a cDropdownItem (click)="logout()" style="cursor: pointer;">
          <svg cIcon class="me-2" name="cilLockLocked"></svg>
          Log Out
        </a>
      </li>
    </ul>
  </c-dropdown>
</ng-template>

