<!--<c-footer>-->
  <div>
    <!-- <a href="https://coreui.io/pro/angular/" target="_blank">BBS</a> -->
    <a>BBS</a>
    <span> &copy; 2024 </span>
  </div>
  <div class="ms-auto">
    Powered by
    <!-- <a href="https://coreui.io/pro/angular" target="_blank"> -->
    <a>
      <!-- <span> Vyankatesh Wale</span> -->
      <span>VP Solutions</span>
    </a>
  </div>
<!--</c-footer>-->
