import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({
   providedIn: 'root'
})
export class FullService {
  public storageDataSource = new BehaviorSubject({});
  storageData = this.storageDataSource.asObservable();

  //public consultingFirmListDataSource = new BehaviorSubject(false);
  //consultingFirmListData = this.consultingFirmListDataSource.asObservable();

  public customerDataSource = new BehaviorSubject(false);
  customerData = this.customerDataSource.asObservable();
   
  //public notificationsDataSource = new BehaviorSubject(false);
  //notificationsData = this.notificationsDataSource.asObservable();

  constructor(private http:HttpClient) { }
  getCustomerList(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/bbs/customer/get-all`)
    .pipe(map(res => res));
  }

  getBranchListByCustomer(customerId: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/bbs/branchInfo/get-all/${customerId}`)
      .pipe(map(res => res));
  }

  getSmsAppStatusBybranch(branchId: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/bbs/notifyDonor/get/sms/app/status/${branchId}`)
      .pipe(map(res => res));
  }

  logOut(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/bbs/logout`)
    .pipe(map(res => res));
  }
}